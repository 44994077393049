import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Loader } from './components/Loader';
import Navbar from './components/Navbar';
import { NotFound } from './components/NotFound';
import { PrivateRoute } from './components/PrivateRoute';
import { Snackbar } from './components/Snackbar';
import { routes } from './constants/RoutesMetaData';
import styles from './global.module.css';
import { SNACKBAR } from './gql/Queries';
import { LocalState } from './models/Types';

const BoardListing = React.lazy(() => import('./pages/BoardListing'));
const Home = React.lazy(() => import('./pages/Home'));
const LoginDev = React.lazy(() => import('./pages/LoginDev'));
const ProjectListing = React.lazy(() => import('./pages/ProjectListing'));
const BoardDetails = React.lazy(() => import('./pages/BoardDetails'));
const PublicBoardDetails = React.lazy(() =>
  import('./pages/PublicBoardDetails'),
);

const Router: React.FC = props => {
  const {
    user,
    logoutUser,
    isLoggedIn,
    isConfirmedUser,
  } = useIdentityContext();
  const {
    data: { snackbarMessage, snackbarShow },
    client,
  } = useQuery<LocalState>(SNACKBAR);

  const onSnackbarClose = () => {
    client.writeQuery<LocalState>({
      query: SNACKBAR,
      data: {
        snackbarShow: false,
      },
    });
  };

  return (
    <BrowserRouter>
      <header className={styles.siteHeader}>
        <Navbar
          title='Retroboard'
          user={user}
          logout={logoutUser}
          isAuthenticated={isLoggedIn && isConfirmedUser}
        />
      </header>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route
            exact
            path={routes.home}
            component={
              process.env.REACT_APP_ENV === 'develop' ? LoginDev : Home
            }
          />
          <PrivateRoute
            path={routes.publicBoardDetails}
            Component={PublicBoardDetails}
          />
          <PrivateRoute path={routes.boardDetails} Component={BoardDetails} />
          <PrivateRoute path={routes.projectDetails} Component={BoardListing} />
          <PrivateRoute path={routes.projects} Component={ProjectListing} />
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
      <Snackbar
        message={snackbarMessage}
        show={snackbarShow}
        onClose={onSnackbarClose}
        duration={5000}
      />
    </BrowserRouter>
  );
};

export default Router;
