import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes } from '../../constants/RoutesMetaData';
import { usePrivate } from '../../hooks/usePrivate';
import { Loader } from '../Loader';

export const PrivateRoute = ({
  Component,
  path,
  ...rest
}: {
  Component: any;
  path: string;
}) => {
  const { loading, isValidUser, isValidUrl } = usePrivate();

  if (loading) return <Loader />;

  if (!isValidUrl)
    return (
      <Redirect
        to={{ pathname: routes.home, state: { fromInvalidUrl: true } }}
      />
    );

  if (!isValidUser) return <Redirect to={routes.home} />;

  return <Route exact path={path} component={Component} {...rest} />;
};
