import '@reach/tooltip/styles.css';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { IdentityContextProvider } from 'react-netlify-identity';
import App from './App';
import './reset.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

Sentry.configureScope(scope => {
  scope.setTag('err_env', 'browser');
});

ReactDOM.render(
  <IdentityContextProvider url={process.env.REACT_APP_NETLIFY_IDENTITY}>
    <App />
  </IdentityContextProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
