export const deleteProjectMessage = (boardsCount: number) => `
There are ${boardsCount} boards in Grant Leading Technology. You will loose all those boards by deleting this project. Are you sure you want to delete it?
`;

export const ToolTipMessages = {
  MarkDown: 'Add mark down such as *, ** etc',
};

export const SnackBarMessages = {
  ProjectFetchError: 'Could not fetch project listing',
  PublicBoardUrlFetchError: 'Could not fetch Public URL',
  PublicBoardDetailsExpiredURL: 'The url has either expired or is malformed',
  UserLoginError: 'Could not validate user',
  BoardDetailsFetchError: 'Could not fetch board Details',
  BoardListingFetchError: 'Could not fetch board Listing',
};
