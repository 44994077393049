import gql from 'graphql-tag';

export const LIKE_FRAGMENT = gql`
  fragment LikeFragment on like {
    id
    userId
    likeType
    user {
      id
      name
      __typename
    }
    __typename
  }
`;

export const ANONYMOUS_CARD_FRAGMENT = gql`
  fragment AnonymousCardFragment on card {
    id
    content
    columnId
    createdOn
    isAnonymous
    user {
      __typename
    }
    likes {
      ...LikeFragment
    }
    __typename
  }
  ${LIKE_FRAGMENT}
`;

export const CARD_FRAGMENT = gql`
  fragment CardFragment on card {
    id
    content
    columnId
    createdOn
    isAnonymous
    user {
      name
      id
      picture
      __typename
    }
    likes {
      ...LikeFragment
    }
    __typename
  }
  ${LIKE_FRAGMENT}
`;

export const COLUMN_FRAGMENT = gql`
  fragment ColumnFragment on column {
    id
    title
    columnColor
    columnOrder
    boardId
    cards(order_by: { createdOn: desc }) {
      ...CardFragment
    }
    __typename
  }
  ${CARD_FRAGMENT}
  ${ANONYMOUS_CARD_FRAGMENT}
`;

export const BOARD_FRAGMENT = gql`
  fragment BoardFragment on board {
    id
    title
    isReadOnly
    projectId
    project {
      id
      logoUrl
      title
      userId
      __typename
    }
    columns(order_by: { createdOn: asc }) {
      ...ColumnFragment
    }
    __typename
  }
  ${COLUMN_FRAGMENT}
`;

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on project {
    id
    title
    logoUrl
    description
    project_users {
      project_id
      user_id
      user {
        id
        name
        email
      }
    }
    boards {
      id
    }
    user {
      id
      name
    }
  }
`;

export const BOARD_LISTING_COLUMN_FRAGMENT = gql`
  fragment BoardListingColumnFragment on column {
    id
    title
    columnColor
    columnOrder
    boardId
    cards {
      id
      __typename
    }
    __typename
  }
`;

export const BOARD_LISTING_BOARD_FRAGMENT = gql`
  fragment BoardListingBoardFragment on board {
    id
    title
    projectId
    isReadOnly
    columns(order_by: { createdOn: asc }) {
      ...BoardListingColumnFragment
    }
    __typename
  }
  ${BOARD_LISTING_COLUMN_FRAGMENT}
`;

export const BOARD_LISTING_PROJECT_FRAGMENT = gql`
  fragment BoardListingProjectFragment on project {
    id
    title
    logoUrl
    project_users {
      project_id
      user_id
      user {
        id
        name
      }
    }
    description
    user {
      name
      id
    }
    boards(order_by: { createdOn: asc }) {
      ...BoardListingBoardFragment
    }
    __typename
  }
  ${BOARD_LISTING_BOARD_FRAGMENT}
`;

export const BOARD_LISTING_BOARD_UPDATE_FRAGMENT = gql`
  fragment BoardListingBoardUpdateFragment on board {
    id
    title
    projectId
    isReadOnly
    __typename
  }
`;
