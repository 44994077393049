import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { Operation } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, Observable, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import React from 'react';
import { ACCESS_DENIED } from '../../constants/Errors';

function getToken() {
  try {
    const token = JSON.parse(localStorage.getItem('gotrue.user')).token
      .access_token;
    return token;
  } catch (err) {
    Sentry.captureException(err);
    return '';
  }
}

function getAnonymousToken() {
  try {
    const token = localStorage.getItem('x-hasura-anonymous-token');
    if (token) return { 'x-hasura-anonymous-token': token };
    else return {};
  } catch (err) {
    Sentry.captureException(err);
    return {};
  }
}

export const ApolloProviderCustom: React.FC<{ token: string }> = props => {
  const { children } = props;

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_API_WS,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        return {
          headers: {
            'content-type': 'application/json',
            Authorization: 'Bearer ' + getToken(),
            ...getAnonymousToken(),
          },
        };
      },
    },
  });

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}`,
    credentials: 'include',
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id,
    addTypename: true,
  });

  const request = (operation: Operation) => {
    operation.setContext({
      headers: {
        ...operation.getContext().headers,
        'content-type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: ZenObservable.Subscription;
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(err => {
            Sentry.captureException(err);
            observer.error.bind(observer);
          });

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          for (let e of graphQLErrors) {
            if (e.extensions.code === ACCESS_DENIED) {
              Sentry.captureException(e);
            }
          }
        } else if (networkError) {
          Sentry.captureException(networkError);
        }
      }),
      requestLink,
      withClientState({
        defaults: {
          snackbarShow: false,
          snackbarMessage: '',
        },
        resolvers: {},
        cache,
      }),
      link,
    ]),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
