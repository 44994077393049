import * as Sentry from '@sentry/browser';
import React from 'react';

interface State {
  hasError: boolean;
  eventId: string;
}

export default class AppErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: { [key: string]: any }) {
    Sentry.withScope(scope => {
      scope.setTag('err_env', 'browser');
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <h2>
          There was some error. Please go back to <a href='/'>Home</a>.
        </h2>
      );
    }
    return this.props.children;
  }
}
