import '@reach/dialog/styles.css';
import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { ApolloProviderCustom } from './components/ApolloClient';
import AppErrorBoundary from './components/AppErrorBoundary';
import RoutesErrorBoundary from './components/RoutesErrorBoundary';
import Router from './Router';

const App = () => {
  const { user } = useIdentityContext();
  return (
    <AppErrorBoundary>
      <ApolloProviderCustom
        token={user && user.token && user.token.access_token}
      >
        <RoutesErrorBoundary>
          <Router />
        </RoutesErrorBoundary>
      </ApolloProviderCustom>
    </AppErrorBoundary>
  );
};

export default App;
