import gql from 'graphql-tag';
import {
  BOARD_FRAGMENT,
  BOARD_LISTING_PROJECT_FRAGMENT,
  PROJECT_FRAGMENT,
} from './Fragments';

export const GET_BOARD_DETAILS = gql`
  query getBoardDetails($id: uuid!) {
    board(where: { id: { _eq: $id } }) {
      ...BoardFragment
    }
  }
  ${BOARD_FRAGMENT}
`;

export const GET_BOARD_DETAILS_SUB = gql`
  subscription getBoardDetails($id: uuid!) {
    board(where: { id: { _eq: $id } }) {
      ...BoardFragment
    }
  }
  ${BOARD_FRAGMENT}
`;

export const GET_LOGGED_IN_USER = gql`
  query {
    loggedInUser @client {
      name
      id
      isLoggedIn
      email
      auth0client {
        id_token
      }
    }
  }
`;

export const GET_BOARD_LISTING = gql`
  query getBoardListing($id: uuid!) {
    project(where: { id: { _eq: $id } }) {
      ...BoardListingProjectFragment
    }
  }
  ${BOARD_LISTING_PROJECT_FRAGMENT}
`;

export const GET_PROJECT_LISTING = gql`
  query getProjectListing {
    project(order_by: { createdOn: asc }) {
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const GET_PROJECT_ACCESSING_USER = gql`
  query getProjectAccessingUser($projectId: uuid!) {
    user(where: { project_users: { project_id: { _eq: $projectId } } }) {
      id
      name
      email
    }
  }
`;

export const GET_USER_LIST = gql`
  query getUsers($str: String!) {
    user(where: { name: { _ilike: $str } }) {
      id
      name
      email
    }
  }
`;

export const SNACKBAR = gql`
  query {
    snackbarShow @client
    snackbarMessage @client
  }
`;
