import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import styles from './Snackbar.module.css';

export interface Props {
  duration: number;
  show: boolean;
  message: string;
  onClose: () => void;
}

export const Snackbar: React.FC<Props> = props => {
  const { message, show, onClose, duration } = props;
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [show]);

  return (
    <div
      id="container"
      className={`${styles.container} ${show ? styles.show : ''}`}
    >
      <div id="message" className={styles.message}>
        {message}
      </div>
      <FaTimes id="cross-btn" className={styles.icon} onClick={onClose} />
    </div>
  );
};
