import { useApolloClient } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { useLocation } from 'react-router-dom';
import { SnackBarMessages } from '../../constants/Messages';
import { LocalState } from '../../models/Types';

export const usePrivate = () => {
  const { isLoggedIn, isConfirmedUser, getFreshJWT } = useIdentityContext();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [isValidUser, setIsValidUser] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const client = useApolloClient();

  useEffect(() => {
    if (isLoggedIn && isConfirmedUser) {
      getFreshJWT()
        .then(jwt => {
          setIsValidUser(true);
          setLoading(false);
        })
        .catch(err => {
          client.writeData<LocalState>({
            data: {
              snackbarShow: true,
              snackbarMessage: SnackBarMessages.UserLoginError,
            },
          });
          Sentry.captureException(err);
          setLoading(false);
        });
    } else {
      const [host, projectString, projectId, boardId, token] = pathname.split(
        '/',
      );

      if (projectString && projectId && token && !host) {
        fetch('/.netlify/functions/verifyPublicToken', {
          method: 'post',
          body: JSON.stringify({
            boardId,
            token,
          }),
        })
          .then(res => {
            if (!res.ok) {
              setIsValidUrl(false);
            } else {
              setIsValidUrl(true);
              setIsValidUser(true);
            }
            setLoading(false);
          })
          .catch(err => {
            client.writeData<LocalState>({
              data: {
                snackbarShow: true,
                snackbarMessage: SnackBarMessages.UserLoginError,
              },
            });
            Sentry.captureException(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
        setIsValidUser(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return { loading, isValidUser, isValidUrl };
};
