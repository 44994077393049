import { RouteComponentProps } from '@reach/router';
import { UserData } from 'gotrue-js';
import { History } from 'history';
import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { routes } from '../../constants/RoutesMetaData';
import styles from './Navbar.module.css';

export interface Props extends RouteComponentProps {
  logout: () => void;
  title: string;
  user: UserData;
  isAuthenticated: boolean;
  history: History;
}

export const Navbar: React.FC<Props> = props => {
  const { logout, user, isAuthenticated, title, history } = props;
  return (
    <nav className={styles.container}>
      <h3>
        <button id='title' onClick={() => history.replace(routes.home)}>
          {title}
        </button>
      </h3>
      {isAuthenticated && user ? (
        <div className={styles.navItems}>
          {user.user_metadata.avatar_url ? (
            <img
              id='user-face'
              alt='user-face'
              src={user.user_metadata.avatar_url}
              className={styles.image}
            />
          ) : (
            ''
          )}
          {user.user_metadata.full_name && (
            <span id='user-name'>{user.user_metadata.full_name}</span>
          )}
          <div className={styles.button}>
            <button
              id='logout-btn'
              className={styles.logout}
              onClick={() => {
                logout();
              }}
            >
              <FiLogOut /> Log Out
            </button>
          </div>
        </div>
      ) : null}
    </nav>
  );
};

export default (props: Omit<Props, 'history'>) => {
  const history = useHistory();
  return <Navbar {...props} history={history} />;
};
